import apiTool from "./index";
import ApiService from "./ApiService";

const GET_DONATIONS = "api/v1/accr/list/";

export default {
    getDonations(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_DONATIONS, payload).then(response => {
            return response.data;
        });
    },
};
