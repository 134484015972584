
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import donationsApi from "@/core/services/DonationsApi";
import Filters from "./crafted/widgets/Filters.vue";
import FilterTags from "./crafted/widgets/FilterTags.vue";

export default defineComponent({
    name: "ACCR",
    components: { Filters, FilterTags },
    data() {
        return {
            perm_pos: "",
            tagsKey: 0,
            activeFilters: {},
            filtersPayload: {},
            filtersToShow: {
                age: false,
                bailliage: false,
                commander: false,
                country: true,
                dues: false,
                function: false,
                gender: false,
                grade: false,
                medal: false,
                member_type: false,
                other_association: false,
                period: false,
                postal_code: false,
                state: false,
                title: false,
                activity_sector: false,
                plaque: false,
                credit_card: false,
                institution_type: false,
                food_style: false,
                stars: false,
                price_category_promotion: false,
                price_category_due: false,
                type_due: false,
                relevant_due_year: false,
                type_chapitre: false,
                amount_accr: true,
                type_accr: true,
                biller_period: false,
                biller_actual_balance: false,
                biller_initial_balance: false,
            },
            relevantYear: "",
            loading: true,
            donationSearch: "",
            periodFrom: "",
            periodTo: "",
            selectedValue: "",
            filterGradeSearch: "",
            donations: [] as any,
            currentPage: 1,
            donationsCount: 0,
            totalPages: 0,
            selectedDonations: [] as any,
            actualFilter: "",
            actualCol: "",
            accrTh: [
                { label: "ID", class: "min-w-60px", colName: "id" },
                { label: "Donator", class: "min-w-60px", colName: "donator" },
                { label: "Date", class: "min-w-60px", colName: "date" },
                { label: "Amount", class: "min-w-60px", colName: "amount" },
                { label: "Email", class: "min-w-60px", colName: "email" },
                { label: "Country", class: "min-w-60px", colName: "country" },
                { label: "Type", class: "min-w-60px", colName: "type" },
                { label: "Status", class: "min-w-60px", colName: "status" },
            ],
        };
    },
    setup() {
        // Number of items per page
        const resultsPerPage = 10;
        const router = useRouter();

        onMounted(() => {
            setCurrentPageBreadcrumbs("ACCR", []);
        });

        return { resultsPerPage, router };
    },
    mounted() {
        this.loading = false;
        this.getDonations();
        this.perm_pos = localStorage.getItem("perm_pos") as any;
    },
    methods: {
        handleSizeChange() {
            this.getDonations();
        },
        applyFilters() {
            var closeFilters = document.getElementById("filter") as any;
            closeFilters.click();
            this.getDonations();
        },
        hasAnyFilters() {
            for (const [key, value] of Object.entries(this.filtersPayload)) {
                if (value !== "") return true;
            }
            return false;
        },
        redirectTo(memberId: any) {
            const routeData = this.router.resolve({
                name: "member-details",
                params: { member_id: memberId },
            });
            window.open(routeData.href, "_blank");
        },
        getDonations() {
            this.loading = true;
            this.selectedDonations = [];
            this.donations = [];
            var payload;

            // On check s'il y a des filtres
            if (this.hasAnyFilters()) {
                if (this.actualFilter && this.actualCol) {
                    // Si filtres & tri
                    payload = {
                        p: this.currentPage,
                        presult: 10,
                        filters: this.filtersPayload,
                        column: this.actualCol,
                        order: this.actualFilter,
                    };
                } else {
                    // Si filtres et pas de tri
                    payload = {
                        p: this.currentPage,
                        presult: 10,
                        filters: this.filtersPayload,
                    };
                }
            } else if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    p: this.currentPage,
                    presult: 10,
                    column: this.actualCol,
                    order: this.actualFilter,
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    p: this.currentPage,
                    presult: 10,
                };
            }

            donationsApi.getDonations(payload).then((res) => {
                this.donations = res.data.accr_list;
                this.donationsCount = res.data.accr_count;
                this.totalPages = res.data.page_count;
                this.loading = false;
            });
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons" + id);

            // Hide all arrows
            Array.from(
                document.getElementsByClassName("chevrons-container") as any
            ).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            this.getDonations();
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-up") as any
                );
            else if (direction === "down")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-down") as any
                );

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        handlePagination(pageNumber: any) {
            this.selectedDonations = [];
            this.currentPage = pageNumber;
            this.getDonations();
        },
        getFiltersPayload(e: any) {
            this.tagsKey++;
            this.filtersPayload = e[0];
            this.activeFilters = e[1];
            this.getDonations();
        },
        queryChanged(query: string) {
            this.donationSearch = query;
            this.getDonations();
        },
    },
});
